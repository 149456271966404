import logo from "./logo.svg";
import "./App.css";
import twinkle from "./assets/images/twinkle.png";
import vivah from "./assets/images/vivah.png";

const offsets = [
    {
        top: 0.65,
        right: 0.8861788617886179,
        scale: 0.5134862798979061,
        opacity: 0.43514907102614186,
    },
    {
        top: 0.125,
        right: 0.0921409214092141,
        scale: 0.6842083865821711,
        opacity: 0.44554970132741745,
    },
    {
        top: 0.625,
        right: 0.05962059620596206,
        scale: 1.006708602222097,
        opacity: 0.8006239276057514,
    },
    {
        top: 0.175,
        right: 0.27100271002710025,
        scale: 0.6,
        opacity: 0.7847701718505224,
    },
    // {
    //     top: 0.475,
    //     right: 0.6612466124661247,
    //     scale: 0.6504881548692939,
    //     opacity: 0.6465822504526619,
    // },
    {
        top: 0.125,
        right: 0.7588075880758808,
        scale: 1,
        opacity: 0.5312738584980181,
    },
];

function App() {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    return (
        <div
            className="App"
            style={{
                background: "linear-gradient(180deg, #ff9865, #bd5e5e)",
                height: "100vh",
                color: "white",
            }}
        >
            <header className="App-header">
                {offsets.map((offset, index) => (
                    <img
                        key={"twinkle" + index}
                        src={twinkle}
                        style={{
                            position: "absolute",
                            width: 45,
                            height: 50,
                            opacity: offset.opacity,
                            right: `calc(${offset.right * 100}vw)`, // convert offset.right to percentage of viewport width
                            top: `calc(${offset.top * 100}vh)`, // convert offset.top to percentage of viewport height

                            transform: `scale(${offset.scale}) rotate(${
                                index % 2 === 0 ? "180deg" : "0deg"
                            })`,
                        }}
                        alt={"twinkle" + index}
                    />
                ))}
                {/* <img src={logo} className="App-logo" alt="logo" /> */}
                <img src={vivah} className="App-logo" alt="logo"/>
                {/* <p className="comingSoonText">Coming Soon</p> */}
            </header>
        </div>
    );
}

export default App;
